import { Form as IForm } from "@ludens-reklame/rubics-v3-sdk/form/types";
import { Image } from "@ludens-reklame/rubics-v3-sdk/file/types";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { Form } from "../../internal-components/form/form.js";
import { RichText } from "../../internal-components/richText/RichText.js";

interface Props {
  form?: IForm;
  contactInfo?: string;
  contactImage?: Image;
}

const ContactForm: React.FC<Props> = ({ form, contactInfo, contactImage }) => {
  if (!form) return null;

  return (
    <div className="contact-form mw vs-xl hs">
      <div className="grid">
        <div className="column form">
          <p className="strapline b3">Kontaktskjema</p>
          {form.description && (
            <div className="description">
              <RichText html={form.description} />
            </div>
          )}
          <Form form={form} />
        </div>
        {(contactInfo || contactImage) && (
          <div className="column embed">
            <p className="strapline b3">Kontaktinfo</p>
            {contactInfo && <RichText html={contactInfo} />}
            {contactImage && (
              <div className="image">
                <img
                  src={transformImageUrl(contactImage, { width: 412 })}
                  alt={contactImage.altText}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
